@font-face {
  font-family: "CircularXX";
  src: url("./assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: "CircularXX-Medium";
  src: url("./assets/fonts/AvenirLTStd-Medium.otf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
