.users-container {
    width: 100%;

    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    
        .practice-dropdown-container {
            display: flex;
            justify-content: right;
        }
    
        .section-title {
            margin: 0;
        }
    }
}