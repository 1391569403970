.stats-card-container {
    display: "flex";
    margin-bottom: 10;
    justify-content: 'space-between'
}

.stats-card {
    min-width: 200px; 
    background: white; 
    margin-right: 26px; 
    border-radius: 14px; 
    overflow: hidden; 
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.03)
}

.line-chart {
    height: 100px;
    width: 100%;
}

.number-count {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
}

.red {
    color: rgb(191, 13, 13);
}

.header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}