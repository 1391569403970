body {
    margin: 0;
}

.ant-layout-header {
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.08);
}

.ant-layout-content {
    background: rgb(244 245 245) !important;
}