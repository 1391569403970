
.auth-container {
  min-height: 100vh;
  background-image: linear-gradient(to bottom, rgba(145, 171, 198, 1) 0%, 35.71428656578064%, rgba(207, 204, 199, 1) 71.42857313156128%, 85.71428656578064%, rgba(213, 181, 158, 1) 100%);

  .auth-preloader {
    position: absolute;
    width: 102px;
    height: 102px;
    left: 50%;
    top: 50%;
    min-height: 102px;
    transform: translateX(-50%) translateY(-50%);
  
    svg {
      width: 102px;
      height: 102px;
    }
  
    .small-circle {
      stroke-dasharray: 210;
      stroke-dashoffset: 210;
      transform-origin: 50%;
      animation: 1s draw-small infinite alternate;
    }
  
    .big-circle {
      stroke-dasharray: 240;
      stroke-dashoffset: 240;
      transform-origin: 50%;
      animation: 1s draw-big infinite alternate 0.5s;
    }
  
    @keyframes draw-small {
      0% {
        stroke-dashoffset: 0;
        transform: rotate(0deg);
      }
  
      100% {
        stroke-dashoffset: 210;
        transform: rotate(360deg);
      }
    }
  
    @keyframes draw-big {
      0% {
        stroke-dashoffset: 0;
        transform: rotateY(180deg) rotate(360deg);
      }
  
      100% {
        stroke-dashoffset: 240;
        transform: rotateY(180deg) rotate(0deg);
      }
    }
  }
}

