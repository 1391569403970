.demo-logo-vertical {
    margin: 16px;
    height: 100%;
    border-radius: 6px;
}

.ant-layout {
    min-height: 100vh;
}

.table-container {
    border-radius: 8px;
    background: white;
}

.ant-table-wrapper .ant-table-thead > tr > th {
    background: white !important;
    border-bottom: 1px solid #1b0e60;
    color: #1b0e60;
}

.main-container {
    display: flex;
    width: 100%;
}

.sidebar {
    min-width: 160px;
    .item {
        cursor: pointer;
        h3 {
            color: #1b0e60;
        }

        &.active {
            text-decoration: underline;
        }
    }
}

.section-header {
    h2 {
        font-size: 30px;
    }
}